<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div style="height: inherit">
    <div class="body-content-overlay"></div>

    <div class="todo-app-list">
      <!-- App Searchbar Header -->
      <div class="app-fixed-search d-flex align-items-center">
        <!-- Toggler -->
        <div class="sidebar-toggle d-block d-lg-none ml-1">
          <feather-icon icon="MenuIcon" size="21" class="cursor-pointer" />
        </div>

        <!-- Searchbar -->
        <div class="d-flex align-content-center justify-content-between w-100">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" class="text-muted" />
            </b-input-group-prepend>
            <b-form-input placeholder="Search Support" v-model="q" />
          </b-input-group>
        </div>

        <!-- Dropdown -->
        <div class="dropdown">
          <b-dropdown variant="link" no-caret toggle-class="p-0 mr-1" right>
            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
            </template>
            <b-dropdown-item @click="$router.push({ name: 'supports-dashboard' })">
              Reset Sort
            </b-dropdown-item>
            <b-dropdown-item :to="{ name: $route.name, query: { ...$route.query, sort: 'assignee' } }">
              Sort Assignee
            </b-dropdown-item>
            <b-dropdown-item :to="{ name: $route.name, query: { ...$route.query, sort: 'reverse-assignee' } }">
              Sort Assignee (Reverse)
            </b-dropdown-item>
            <b-dropdown-item :to="{ name: $route.name, query: { ...$route.query, sort: 'support-date' } }">
              Sort Request Date
            </b-dropdown-item>
            <b-dropdown-item :to="{ name: $route.name, query: { ...$route.query, sort: 'reverse-support-date' } }">
              Sort Request Date (Reverse)
            </b-dropdown-item>
            <b-dropdown-item @click="downloadReportSupport">
              Download Report
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>

      <!-- Todo List -->
      <vue-perfect-scrollbar :settings="perfectScrollbarSettings" class="todo-task-list-wrapper list-group scroll-area">
        <b-overlay :show="loading" rounded="sm" no-fade>
          <div class="todo-list-container">
            <table class="todo-table">
              <thead>
                <tr class="todo-header ">
                  <th class="pr-0 mr-0" style="font-weight: bold;">#</th>
                  <th style="font-weight: bold;">Full Name</th>
                  <th style="font-weight:bold;">
                <div>
                  <button style="font-weight:bold; border: none; cursor: pointer; width:100%; height: 50px; background-color:transparent" id="role-filter-button" class="text-left">
                    Client
                    <i class="fa fa-chevron-down" style="position: absolute; right: 7px; top: 50%; transform: translateY(-50%);"></i>
                  </button>
                  <b-popover ref="roleFilterPopover" triggers="click blur" placement="bottom" :target="'role-filter-button'" style="border: none; box-shadow: none;">
                    <div class="p-50" style="max-height: 300px; overflow-y: auto; border: 1px solid #ccc; border-radius: 5px; background-color: white;">
                      <input type="text" v-model="clientFilter" placeholder="Search" style="padding: 5px; width: 100%; margin-bottom: 10px; border: 1px solid #ccc; border-radius: 5px;" />
                      <label v-if="filteredClients.length > 0" class="checkbox mt-1" style="margin-left: 10px; font-weight: bold;">
                        <input type="checkbox" @change="selectAllClients" v-model="checkboxAllClients" style="margin-right: 5px;" />
                        Select All
                      </label>
                      <hr class="mt-1 mb-1" style="border-color: #ccc;" />
                      <ul v-if="filteredClients.length > 0" style="list-style: none; padding-left: 0;">
                        <li v-for="(role, index) in filteredClients" :key="index" style="margin-bottom: 5px;">
                          <label class="checkbox" style="margin-left: 10px;">
                            <input type="checkbox" :value="role.id" v-model="selectedClients" style="margin-right: 5px;" />
                            {{ role.name }}
                          </label>
                        </li>
                      </ul>
                      <span v-else style="margin-left: 10px;">No role found.</span>
                    </div>
                    <button @click="applyFilter('client')" class="btn btn-primary" style="width: 100%; margin-top: 10px;">
                      Apply Filter
                    </button>
                  </b-popover>
                </div>
              </th>
                  <th style="font-weight: bold;">Email</th>
                  <th style="font-weight: bold;">Team</th>
                  <th style="font-weight: bold;">Category</th>
                  <th style="font-weight: bold;">Status</th>
                  <th style="font-weight: bold; white-space: nowrap;">Request Date</th>
                  <th style="font-weight: bold;">Assignee</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(support, index) in supports" :key="index" class="todo-row">
                  <td class="todo-cell">
                    <div class="custom-checkbox">
                      <span class="checkbox-icon" :class="{ checked: support.isCompleted }" @click="updateSupportIsCompleted(support)"></span>
                    </div>
                  </td>
                  <td @click="handleSupportClick(support)" class="todo-cell" style="white-space: nowrap;">{{ support.firstName }} {{ support.lastName }}</td>
                  <td @click="handleSupportClick(support)" class="todo-cell" style="white-space: nowrap;">{{ support.get_client  ? support.get_client.name : null}}</td>
                  <td @click="handleSupportClick(support)" class="todo-cell" style="white-space: nowrap;">{{ support.email }}</td>
                  <td class="todo-cell" style="white-space: nowrap;">
                    <div class="avatar-wrapper">
                      <button :style="'background-color:' + resolveTeamVariant(support.team)" style=" color: #000; font-size: 0.9em; border: none; padding: 3px 8px; border-radius: 6px; cursor: pointer; width:100%">{{ resolveTeamText(support.team) }}</button>
                    </div>
                  </td>
                  <td class="todo-cell" style="white-space: nowrap;">
                    <button
                      :style="{
                        backgroundColor: getSectionBgColor(support.section),
                        color: getSectionColor(support.section),
                      }"
                      style="font-size: 0.9em; border: none; padding: 3px 8px; border-radius: 6px; cursor: pointer; width:100%"
                      :id="'section-controls-' + support.id.toString()"
                    >
                      {{ support.section }}
                    </button>

                    <!-- <b-popover :target="'section-controls-' + support.id.toString()" triggers="click" variant="primary" placement="bottomright" :custom-class="'my-custom-popover'"> -->
                    <b-popover :target="'section-controls-' + support.id.toString()" v-if="$Can('supports_change_section')" triggers="focus" variant="primary" placement="left">
                      <div class="d-flex flex-column">
                        <button
                          v-for="section in supportFilters"
                          :key="section.title"
                          class="mb-50 button-container hoverable-button"
                          @click="changeSupportSection(support, section, 'section-controls-' + support.id.toString())"
                          :style="{
                            backgroundColor: section.bgColor,
                            color: section.textColor,
                            'font-size': '0.9em',
                            border: '1px solid #ccc',
                            'box-shadow': '0px 3px 7px rgba(0, 0, 0, 0.1)',
                            padding: '6px',
                            'border-radius': '0px',
                            cursor: 'pointer',
                            width: '100%',
                          }"
                        >
                          {{ section.title }}
                        </button>
                      </div>
                    </b-popover>
                  </td>
                  <td class="todo-cell" style="white-space: nowrap;">
                    <button
                      :style="{
                        backgroundColor: getStatusBgColor(support.supportStatus),
                        color: getStatusColor(support.supportStatus),
                      }"
                      style="font-size: 0.9em; border: none; padding: 3px 8px; border-radius: 6px; cursor: pointer; width:100%"
                      :id="'status-controls-' + support.id.toString()"
                    >
                      {{ support.supportStatus }}
                    </button>

                    <!-- <b-popover :target="'section-controls-' + support.id.toString()" triggers="click" variant="primary" placement="bottomright" :custom-class="'my-custom-popover'"> -->
                    <b-popover :target="'status-controls-' + support.id.toString()" triggers="focus"  v-if="$Can('supports_change_status')" variant="primary" placement="left">
                      <div class="d-flex flex-column">
                        <button
                          v-for="section in supportStatuses"
                          :key="section.title"
                          class="mb-50 button-container hoverable-button"
                          @click="changeSupportStatus(support, section, 'status-controls-' + support.id.toString())"
                          :style="{
                            backgroundColor: section.bgColor,
                            color: section.textColor,
                            'font-size': '0.9em',
                            border: '1px solid #ccc',
                            'box-shadow': '0px 3px 7px rgba(0, 0, 0, 0.1)',
                            padding: '6px',
                            'border-radius': '0px',
                            cursor: 'pointer',
                            width: '100%',
                          }"
                        >
                          {{ section.title }}
                        </button>
                      </div>
                    </b-popover>
                  </td>
                  <td class="todo-cell" style="white-space: nowrap;">{{ formatDate(support.created_at, { month: 'short', day: 'numeric' }) }}</td>
                  <td class="todo-cell" style="white-space: nowrap;">
                    <div class="avatar-wrapper">
                      <b-avatar v-if="support.get_assigned_user" :text="getAvatarText(support.get_assigned_user.name + ' ' + support.get_assigned_user.surname)" variant="light-primary" size="26" class="mr-25"> </b-avatar>
                      <span v-if="support.get_assigned_user">{{ support.get_assigned_user.name }} {{ support.get_assigned_user.surname }}</span>
                      <span v-else></span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="no-results" :class="{ show: !supports.length }">
            <h5>No Items Found</h5>
          </div>
        </b-overlay>
      </vue-perfect-scrollbar>
    </div>

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-left">
      <todo-left-sidebar @navigate-dashboard="fetchSupports" :support-tags="supportTags" :support-statuses.sync="supportStatuses" :is-support-handler-sidebar-active.sync="isSupportHandlerSidebarActive" :support-filters.sync="supportFilters" />
    </portal>

    <!-- support Handler -->
    <todo-support-handler-sidebar v-model="isSupportHandlerSidebarActive" :support-statuses="supportStatuses" :support-data.sync="supportData" :blank-support="blankSupport" :support-filters="supportFilters" />
  </div>
</template>

<script>
import store from '@/store';
import { ref, watch, computed, onUnmounted } from '@vue/composition-api';
import { BPopover, VBPopover, BFormInput, BInputGroup, BInputGroupPrepend, BDropdown, BDropdownItem, BFormCheckbox, BBadge, BAvatar } from 'bootstrap-vue';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import draggable from 'vuedraggable';
import TodoLeftSidebar from './TodoLeftSidebar.vue';
import supportsStoreModule from './supportsStoreModule.js';
import TodoSupportHandlerSidebar from './TodoSupportHandlerSidebar.vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import _ from 'lodash';
import { getAvatarText, resolveTeamVariant, resolveTeamText } from '../helpers';
import router from '@/router';

export default {
  components: {
    BPopover,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    BBadge,
    BAvatar,
    draggable,
    VuePerfectScrollbar,

    // App
    TodoLeftSidebar,
    TodoSupportHandlerSidebar,
  },
  directives: {
    'b-popover': VBPopover,
  },
  setup() {
    const SUPPORT_APP_STORE_MODULE_NAME = 'supports';
    // Register module
    if (!store.hasModule(SUPPORT_APP_STORE_MODULE_NAME)) store.registerModule(SUPPORT_APP_STORE_MODULE_NAME, supportsStoreModule);
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SUPPORT_APP_STORE_MODULE_NAME)) store.unregisterModule(SUPPORT_APP_STORE_MODULE_NAME);
    });
  },

  data() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    };

    const supportTags = [
      { title: 'EXCEL', color: 'success', route: { name: 'supports-dashboard-team', params: { team: 'EXCEL' } } },
      { title: 'WEB', color: 'primary', route: { name: 'supports-dashboard-team', params: { team: 'WEB' } } },
      { title: 'OTHER', color: 'secondary', route: { name: 'supports-dashboard-team', params: { team: 'OTHER' } } },
    ];

    const blankSupport = {
      id: null,
      assignedUser: null,
      userId: null,
      firstName: null,
      lastName: null,
      source: 'PORTAL',
      email: null,
      phone: null,
      method: null,
      message: null,
      section: null,
      team: null,
      section: 'New Supports',
      status: 'A',
    };

    const supportData = JSON.parse(JSON.stringify(blankSupport));

    return {
      blankSupport,
      supportData,
      perfectScrollbarSettings,
      searchQuery: '',
      supports: [],
      supportTags,
      isSupportHandlerSidebarActive: false,
      loading: true,
      sortOptions: ['latest', 'title-asc', 'title-desc', 'assignee', 'due-date'],
      supportFilters: [],
      supportStatuses: [],
      selectedClients:[],
      clientsData:[],
      checkboxAllClients:false,
      clientFilter:'',
      q: null,

      popoverShow: false,
    };
  },

  created() {
    this.fetchClients();
    this.fetchSupports();
  },

  watch: {

    selectedClients(newSelectedRoles) {
      this.checkboxAllClients = newSelectedRoles.length === this.clientsData.length;
    },

    isSupportHandlerSidebarActive(newVal) {
      if (newVal) {
      } else {
        this.fetchSupports();
      }
    },

    '$route.params.filter'(newFilter, oldFilter) {
      this.q = null;
      this.fetchSupports(null, newFilter);
    },

    '$route.params.team'(newTeam, oldTeam) {
      if (newTeam != undefined && newTeam != null) {
        this.q = null;
        this.fetchSupports(null, null, newTeam);
      }
    },

    '$route.query.sort'(newSort, oldSort) {
      if (newSort != undefined && newSort != null) {
        this.fetchSupports(null, null, null, newSort);
      }
    },
    '$route.params.status'(newStatus, oldStatus) {
      if (newStatus != undefined && newStatus != null) {
        this.q = null;
        this.fetchSupports(null, null, null, null, newStatus);
      }
    },

    q: _.debounce(function(val) {
      this.fetchSupports(val);
    }, 500),
  },

  methods: {


    downloadReportSupport(){



      store
        .dispatch('supports/downloadReportSupport', {
        
          supports:this.supports.map((e)=>{return e.id})
        })
        .then((res) => {
          this.loading = false;
          if (res.status == 200) {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'SupportRequestsLog.pdf');
            document.body.appendChild(link);
            link.click();

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Successful',
                text: '✔️ Report is created!',
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            });
            this.download = false;
          }
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              text: '❌ Please try again or report an issue to support.',
              icon: 'XIcon',
              variant: 'danger',
            },
          });
          this.loading = false;
          console.log(error);
        });


    },

    applyFilter(){
      this.fetchSupports();
    },

    selectAllClients() {
      this.selectedClients = this.checkboxAllClients ? this.clientsData.map((role) => role.id) : [];
    },

    fetchSupports(q = null, filter = null, team = null, sortBy = null, status = null) {
      filter = filter ?? this.$route.params.filter;
      team = team ?? this.$route.params.team;
      status = status ?? this.$route.params.status;
      q = q ?? this.q;
      sortBy = sortBy ?? this.$route.query.sort;

      this.loading = true;
      this.supports = [];

      store
        .dispatch('supports/fetchSupports', {
          q: q,
          filter: filter,
          team: team,
          status: status,
          sortBy: sortBy,
          client:this.selectedClients
        })
        .then((res) => {
          this.supports = res.data;
          this.loading = false;
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              text: '❌ Please try again or report an issue to support.',
              icon: 'XIcon',
              variant: 'danger',
            },
          });
          this.loading = false;
          console.log(error);
        });
    },

    updateSupportIsCompleted(val) {
      this.loading = true;
      store
        .dispatch('supports/updateSupportIsCompleted', val)
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              text: 'Support was updated successfully! 👍',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          });
          this.fetchSupports();
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              text: '❌ Please try again or report an issue to support.',
              icon: 'XIcon',
              variant: 'danger',
            },
          });
          console.log(error);
        });
    },

    showSectionControlsPopover(targetSupport, support) {
      this.$root.$emit('bv::show::popover', targetSupport);
    },

    handleSupportClick(val) {
      // if(this.$Can('supports_view')){
        this.supportData = val;
      this.isSupportHandlerSidebarActive = true;
      // }
    
    },

    changeSupportSection(support, section, targetSupport) {
      const tempData = {
        id: support.id,
        section: section.title,
      };

      this.loading = true;
      store
        .dispatch('supports/updateSection', tempData)
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              text: 'Support was updated successfully! 👍',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          });
          this.$root.$emit('bv::hide::popover', targetSupport);
          // router.push({ name: 'supports-dashboard' });
          this.fetchSupports();
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              text: '❌ Please try again or report an issue to support.',
              icon: 'XIcon',
              variant: 'danger',
            },
          });
          console.log(error);
        });
    },

    fetchClients(){
    
      
      store
        .dispatch('supports/fetchSupportClients', {
        })
        .then((res) => {
          this.clientsData = res.data.clients;
          this.loading = false;
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              text: '❌ Please try again or report an issue to support.',
              icon: 'XIcon',
              variant: 'danger',
            },
          });
          this.loading = false;
          console.log(error);
        });


    },

    changeSupportStatus(support, section, targetSupport) {
      const tempData = {
        id: support.id,
        supportStatus: section.title,
      };

      this.loading = true;
      store
        .dispatch('supports/updateStatus', tempData)
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              text: 'Support was updated successfully! 👍',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          });
          this.$root.$emit('bv::hide::popover', targetSupport);
          // router.push({ name: 'supports-dashboard' });
          this.fetchSupports();
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              text: '❌ Please try again or report an issue to support.',
              icon: 'XIcon',
              variant: 'danger',
            },
          });
          console.log(error);
        });
    },

    formatDate(value, formatting = { day: 'numeric', month: 'short', year: 'numeric' }) {
      if (!value) return value;
      const date = new Date(value);
      const day = date
        .getDate()
        .toString()
        .padStart(2, '0');
      const month = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(date); // Kısa ay adını alır
      const year = date
        .getFullYear()
        .toString()
        .slice(-2);
      return `${day} ${month}  ${year}`;
    },

    getSectionColor(val) {
      const section = this.supportFilters.find((section) => section.title === val);
      return section ? section.textColor : '#FFFFFF';
    },

    getSectionBgColor(val) {
      const section = this.supportFilters.find((section) => section.title === val);
      return section ? section.bgColor : '#000000';
    },

    getStatusColor(val) {
      const status = this.supportStatuses.find((status) => status.title === val);
      return status ? status.textColor : '#FFFFFF';
    },

    getStatusBgColor(val) {
      const status = this.supportStatuses.find((status) => status.title === val);
      return status ? status.bgColor : '#000000';
    },

    getAvatarText,
    resolveTeamVariant,
    resolveTeamText,
  },
  computed:{
    filteredClients() {
      return this.clientsData.filter((client) => client.name.toLowerCase().includes(this.clientFilter.toLowerCase()));
    },
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
@import '~@core/scss/base/pages/app-todo.scss';
</style>

<style lang="scss" scoped>
.todo-table {
  width: 100%;
  border-collapse: collapse;
  border: none;
}

.todo-header {
  background-color: #f5f5f5;
}

.todo-header th,
.todo-cell {
  padding: 7px;
  border-right: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
}

.todo-cell .avatar-wrapper {
  display: flex;
  align-items: center;
}

.no-results {
  text-align: center;
  padding: 20px;
}

.no-results.show {
  display: block;
}

.todo-header th {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  background-color: #f5f5f5;
  z-index: 2;
}

.todo-row:hover {
  background-color: #f2f2f2;
  transition: background-color 0.2s ease-in-out;
  cursor: pointer;
}

.hoverable-button:hover {
  background-color: #ebebeb !important;
  color: black !important;
  transition: background-color 0.3s;
}

/* Custom styles for the checkbox */
/* Custom styles for the checkbox */
.custom-checkbox {
  position: relative;
}

/* Style the custom checkbox container */
.custom-checkbox .checkbox-icon {
  width: 15px;
  height: 15px;
  border: 1px solid #ccc;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50px;
}
/* Style the unchecked state of the checkbox icon */
.custom-checkbox .checkbox-icon:not(.checked)::before {
  content: '\2713'; /* Unicode checkmark character */
  font-size: 14px;
  color: #ccc; /* Gray color for the tick when unchecked */
}

/* Style the checked state of the checkbox icon */
.custom-checkbox .checkbox-icon.checked {
  background-color: #198754; /* Green background when checked */
}

/* Style the tick icon (a simple checkmark) when checked */
.custom-checkbox .checkbox-icon.checked::before {
  content: '\2713'; /* Unicode checkmark character */
  font-size: 14px;
  color: #fff; /* White color for the tick when checked */
}
</style>
