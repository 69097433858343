<template>
  <div>
    <b-modal id="modal-manage-section" :no-close-on-backdrop="true" :no-close-on-esc="true" hide-footer centered title="Manage Categories">
      <b-list-group class="list-group-filters">
        <b-list-group-item v-for="filter in supportFilters" :key="filter.title">
          <div v-if="!filter.editing">
            <feather-icon :icon="filter.icon" size="18" class="mr-75" />
            <span class="align-text-bottom line-height-1">{{ filter.title }}</span>

            <div v-if="filter.title != 'Archived' && filter.title != 'Dead Supports'" class="close-button" @click="editSection(filter)" style="cursor: pointer; position: absolute; top: 7px; right: 25px;">
              <feather-icon icon="EditIcon" size="14" class="mr-75" />
            </div>
            <div v-if="filter.title != 'Archived' && filter.title != 'Dead Supports'" class="close-button" @click="removeSection(filter)" style="cursor: pointer; position: absolute; top: 0px; right: 10px;">
              <span style="font-size: 2em; color:red">&times;</span>
            </div>
          </div>
          <div v-else>
            <b-form-input class="align-text-bottom line-height-1 w-50" v-model="filter.newName"></b-form-input>
            <div v-if="filter.title != 'Archived'" class="close-button" @click="saveEditedSection(filter)" style="cursor: pointer; position: absolute; top: 14px; right: 5px;">
              <feather-icon icon="CheckIcon" style="color:green" size="20" />
            </div>
          </div>
        </b-list-group-item>
      </b-list-group>

      <div class="px-2 mt-2 d-flex justify-content-end" v-b-modal.modal-create-section style="cursor:pointer">
        <h6 class="add-section-label mb-1 mt-50"><feather-icon icon="PlusIcon" size="18" class="mr-75" /> Add Category</h6>
      </div>
    </b-modal>

    <b-modal id="modal-section-move" ref="modal-section-move" cancel-variant="outline-secondary" ok-title="Save" :ok-disabled="moveToSection == null" @ok="handleUpdateGroupDates" :no-close-on-backdrop="true" centered>
      <template #modal-header="{ close }">
        <span>Move category</span>
      </template>
      <b-form-group :state="moveToSection ? true : false" invalid-feedback="New Section field is required" label-for="newSection">
        <v-select id="newSection" v-model="moveToSection" :options="supportFilters.filter((e) => e.title != removeSectionName)" label="title" :reduce="(val) => val.title" class="small-v-select" :clearable="false" placeholder="Choose a new Section"></v-select>
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
import { BButton, BModal, VBModal, BForm, BFormInput, BFormGroup } from 'bootstrap-vue';
import vSelect from 'vue-select';
import Ripple from 'vue-ripple-directive';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import store from '@/store';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import AppColorPaletteVue from '@/@core/layouts/components/AppColorPalette.vue';

export default {
  components: {
    BButton,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    vSelect,

    //Component
    AppColorPaletteVue,
    // Form Validation

    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
    };

    return {
      required,
      sectionName: null,

      selectedTextColor: '',
      selectedBgColor: '',

      perfectScrollbarSettings,
      moveToSection: null,
      removeSectionName: null,
    };
  },

  created() {},

  props: {
    supportFilters: Array,
  },

  methods: {
    handleUpdateGroupDates(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();
      // Trigger submit handler
    },

    editSection(section) {
      section.newName = section.title;
      section.editing = true;
    },

    saveEditedSection(section) {
      const cleanedSectionName = this.cleanAndNormalizeText(section.newName);
      const isDuplicate = this.supportFilters.some((section) => this.cleanAndNormalizeText(section.title) === cleanedSectionName);
      if (isDuplicate) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Warning',
            text: 'Category with the same name already exists.',
            icon: 'AlertTriangleIcon',
            variant: 'warning',
          },
        });
        return;
      } else {
        store
          .dispatch('supports/updateSectionName', { id: section.id, newName: section.newName })
          .then((response) => {
            if (response.data == 'ok') {
              section.title = section.newName;
            }
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Success',
                text: 'Category was updated successfully! 👍',
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            });
          })
          .catch((error) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                text: '❌ Please try again or report an issue to support.',
                icon: 'XIcon',
                variant: 'danger',
              },
            });
            console.log(error);
          });
      }
      section.editing = false;
    },

    removeSection(section) {
      this.removeSectionName = section.title;

      this.$swal({
        title: 'Are you sure?',
        text: '',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, remove the section!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$bvModal
            .msgBoxConfirm('Would you like to move the support requests to another category? (Otherwise, they will be archived.)', {
              title: 'Please Confirm',
              size: 'sm',
              okVariant: 'primary',
              okTitle: 'Yes',
              cancelTitle: 'No',
              cancelVariant: 'outline-secondary',

              hideHeaderClose: false,
              noCloseOnBackdrop: true,
              noCloseOnEsc: true,
              centered: true,
            })
            .then((value) => {
              if (value != null) {
                if (value) {
                  this.$bvModal.show('modal-section-move');

                  const modalPromise = new Promise((resolve) => {
                    this.$refs['modal-section-move'].$once('ok', () => {
                      resolve();
                    });
                  });

                  modalPromise.then(() => {
                    if (this.moveToSection != null) {
                      store
                        .dispatch('supports/removeSection', { section: section.title, newSection: this.moveToSection })
                        .then((res) => {
                          this.$toast({
                            component: ToastificationContent,
                            props: {
                              title: 'Success',
                              text: 'Category has been removed successfully! 👍',
                              icon: 'CheckCircleIcon',
                              variant: 'success',
                            },
                          });

                          this.newSection = null;
                          this.removeSectionName = null;
                          this.$emit('section-added');
                          this.$bvModal.hide('modal-section-move');
                        })
                        .catch((error) => {
                          this.$toast({
                            component: ToastificationContent,
                            props: {
                              title: 'Error',
                              text: '❌ There was an error removing the category. Please try again or report an issue to support.',
                              icon: 'XIcon',
                              variant: 'danger',
                            },
                          });
                          this.loading = false;
                          console.log(error);
                        });
                    }
                  });
                } else {
                  this.loading = true;

                  store
                    .dispatch('supports/removeSection', { section: section.title, newSection: null })
                    .then((res) => {
                      this.newSection = null;
                      this.removeSectionName = null;
                      this.$bvModal.hide('modal-section-move');

                      this.$emit('section-added');

                      this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: 'Success',
                          text: 'Category has been removed successfully! 👍',
                          icon: 'CheckCircleIcon',
                          variant: 'success',
                        },
                      });
                    })
                    .catch((error) => {
                      this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: 'Error',
                          text: '❌ There was an error removing the category. Please try again or report an issue to support.',
                          icon: 'XIcon',
                          variant: 'danger',
                        },
                      });
                      this.loading = false;
                      console.log(error);
                    });
                }
              }

              this.loading = false;
              this.$bvModal.hide('modal-section-move');
              this.$emit('section-added');
            });
        } else {
          this.$bvModal.hide('modal-section-move');
        }
      });
    },

    colorSelectedHandler(textColor, bgColor) {
      this.selectedTextColor = textColor;
      this.selectedBgColor = bgColor;
    },

    cleanAndNormalizeText(text) {
      return text
        .replace(/\s+/g, ' ')
        .trim()
        .toLowerCase(); // Boşlukları düzenle ve küçük harfe çevir
    },
  },
};
</script>

<style scoped>
.add-section-label {
  color: rgb(148, 148, 148);
}

.add-section-label:hover {
  color: rgb(88, 88, 88);
}

.all-section-label {
  color: #4e5154;
}

.all-section-label:hover {
  color: #73c0d2;
}

.list-group-item {
  transition: all 1s;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
